import { useEffect, useState } from "react";
import { Chip, 
    FormControl, 
    InputLabel, 
    makeStyles, 
    MenuItem, 
    Select 
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
}));

const PermissionSelect = ({ selectedPermissionIds, onChange }) => {
    const classes = useStyles();
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        const fetchPermissions = async () => {
            try {   
                const { data } = await api.get("/permissions");
                setPermissions(data);
            } catch (err) {
                toastError(err);
            }
        };
        fetchPermissions();
	}, []);

    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div style={{ marginTop: 6 }}>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel>{i18n.t("permissionSelect.inputLabel")}</InputLabel>
				<Select
					multiple
					labelWidth={60}
					value={selectedPermissionIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
					renderValue={selected => (
						<div className={classes.chips}>
							{selected?.length > 0 &&
								selected.map(id => {
									const permission = permissions.find(q => q.id === id);
									const index = permissions.indexOf(permission);
									return permission ? (
										<Chip
											key={id}
											style={{ backgroundColor: green[500] }}
											variant="outlined"
											label={i18n.t("permissionSelect.options", { returnObjects: true })[index]}
											className={classes.chip}
										/>
									) : null;
								})}
						</div>
					)}
				>
					{permissions.map((permission, index) => (
						<MenuItem key={permission.id} value={permission.id}>							
							{i18n.t("permissionSelect.options", { returnObjects: true })[index]}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
    );
};

export default PermissionSelect;