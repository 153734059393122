import { Avatar, Badge, Divider, ListItem, ListItemAvatar, ListItemText, makeStyles, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useParams } from "react-router-dom";
import { i18n } from "../../translate/i18n";
import clsx from "clsx";
import MarkdownWrapper from "../../components/MarkdownWrapper";
import TicketDate from "../../components/TicketDate";

const useStyles = makeStyles(theme => ({
    ticket: {
        position: "relative",
        backgroundColor: theme.palette.background.inverse
    },
    ticketQueueColor: {
        flex: "none",
        width: "8px",
        height: "100%",
        position: "absolute",
        top: "0%",
        left: "0%",
    },
    contactNameWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    lastMessageTime: {
        justifySelf: "flex-end",
    },
    columnContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 5
    },
    tag: {
        marginRight: 15,
        right: 5,
        bottom: 5,
        color: "#ffffff",
        padding: 1,
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 6,
        fontSize: "0.9em"
    },
    primaryTag: {
        background: "#2576D2",
    },
    secondaryTag: {
        background: "#d27a25",
    },
    contactLastMessage: {
        paddingRight: 20,
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxHeight: "3em",
        whiteSpace: "normal"
    },
    badgeStyle: {
        position: "absolute",
        top: "10px",
        right: "10px",
        color: "white",
        zIndex: 1
    },
}));

const Ticket = ({ ticket, onClick }) => {
    const classes = useStyles();
    const { ticketId } = useParams();

    const handleClick = () => {
        onClick(ticket.id);
    };

    return (
        <>
            <ListItem
                dense
                button
                onClick={handleClick}
                selected={ticketId && +ticketId === ticket.id}
                className={classes.ticket}
            >
                <Tooltip
                    arrow
                    title={ticket.queue?.name || "Sem fila"}
                >
                    <span
                        style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
                        className={classes.ticketQueueColor}
                    ></span>
                </Tooltip>
                <ListItemAvatar>
                    <Avatar src={ticket?.contact?.profilePicUrl} />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <span className={classes.contactNameWrapper}>
                            <div className={classes.columnContainer}>
                                <Typography
                                    noWrap
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {ticket.contact.name}
                                </Typography>
                                <Typography
                                    className={classes.contactLastMessage}
                                    noWrap
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {ticket.lastMessage ? (
                                        <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                                    ) : (
                                        <br />
                                    )}
                                </Typography>
                            </div>
                            <div className={classes.contactNameWrapper}>
                                <div className={classes.columnContainer}>
                                    {ticket.user && (
                                        <>
                                            {i18n.t("messagesList.header.assignedTo")}
                                            <div className={clsx(classes.tag, classes.secondaryTag)}>
                                                {ticket?.user?.name}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className={classes.columnContainer}>
                                    <Typography
                                        className={classes.lastMessageTime}
                                        component="span"
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        <TicketDate
                                            date={ticket.updatedAt}
                                        />
                                    </Typography>
                                    <div
                                        className={clsx(classes.tag, classes.primaryTag)}
                                        title={i18n.t("ticketsList.connectionTitle")}
                                    >
                                        {ticket.whatsapp?.name}
                                    </div>
                                </div>
                            </div>
                        </span>
                    }
                    secondary={
                        <span className={classes.contactNameWrapper}>
                            <Badge
                                className={classes.badgeStyle}
                                badgeContent={ticket.unreadMessages}
                                overlap="rectangular"
                                color="secondary"
                            />
                        </span>
                    }
                />
            </ListItem>
            <Divider component="li" />
        </>
    );
};

export default Ticket;