/* eslint-disable no-use-before-define */
const createImmutableObject = (obj) => Object.freeze(obj);

const hasPermission = (user, permission) =>
	user?.permissions && user?.permissions?.map(permission => permission.permission)?.includes(permission);

const RuleType = createImmutableObject({
	DRAWER: createImmutableObject({
		ADMIN_ITEMS_VIEW: "drawer-admin-items:view",
		MASTER_ITEMS_VIEW: "drawer-master-items:view",
	}),
	TICKETS_MANAGER: createImmutableObject({
		SHOW_ALL: "tickets-manager:showall",
		SPY: "tickets-manager:spy",
	}),
	USER_MODAL: createImmutableObject({
		EDIT_PROFILE: "user-modal:editProfile",
		EDIT_QUEUES: "user-modal:editQueues",
		EDIT_PROFILE_MASTER: "user-modal:editProfileMaster",
	}),
	TICKET_OPTIONS: createImmutableObject({
		DELETE: "ticket-options:deleteTicket",
		TRANSFER_WHATSAPP: "ticket-options:transferWhatsapp",
	}),
	CONTACTS_PAGE: createImmutableObject({
		DELETE_CONTACT: "contacts-page:deleteContact",
	}),
});

const commonStatic = [
	RuleType.DRAWER.ADMIN_ITEMS_VIEW,
	RuleType.TICKETS_MANAGER.SHOW_ALL,
	RuleType.USER_MODAL.EDIT_PROFILE,
	RuleType.USER_MODAL.EDIT_QUEUES,
	RuleType.TICKET_OPTIONS.DELETE,
	RuleType.TICKET_OPTIONS.TRANSFER_WHATSAPP,
	RuleType.CONTACTS_PAGE.DELETE_CONTACT,
];

const commonAttributes = {
	[RuleType.TICKETS_MANAGER.SPY]: (user) => hasPermission(user, RuleType.TICKETS_MANAGER.SPY)
};

const rules = {
	user: {
		static: [],
	},
	admin: {
		static: commonStatic,
		attributes: {
			...commonAttributes,
		},
	},
	master: {
		static: [
			...commonStatic,
			RuleType.DRAWER.MASTER_ITEMS_VIEW,
			RuleType.USER_MODAL.EDIT_PROFILE_MASTER,
		],
		attributes: {
			...commonAttributes,
		},
	},
};

export { rules, RuleType };
