import { useEffect, useRef, useState } from "react";
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { Field, Form, Formik } from "formik";
import { green } from "@material-ui/core/colors";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textContainer: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
}))

const planSchema = Yup.object().shape({
    name: Yup.string().required(),
    users: Yup.number().min(0).required(),
    connections: Yup.number().min(0).required(),
    queues: Yup.number().min(0).required(),
    isIntegrationMode: Yup.boolean().required(),
    value: Yup.number().min(0).required()
});

const PlanModal = ({ open, onClose, planId }) => {
    const initialValue = {
        name: "",
        users: 0,
        connections: 0,
        queues: 0,
        isIntegrationMode: false,
        value: 0
    };
    const classes = useStyles();
    const [plan, setPlan] = useState(initialValue);
    const isMounted = useRef(true);
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    useEffect(() => {
        if (!planId) return;
       fetchPlan();
       // eslint-disable-next-line
    }, [planId]);
    const fetchPlan = async () => {
        const { data } = await api.get(`/plans/${planId}`);
        if (!isMounted.current) return;
        setPlan(data);
    };
    const handleClose = () => {
        onClose();
        setPlan(initialValue);
    };
    const handleSavePlan = async (values) => {        
        try {
            const request = planId
                ? api.put(`/plans/${planId}`, values)
                : api.post("/plans", values);
            await request;
            handleClose();
            toast.success(i18n.t("planModal.success"));
        } catch (error) {            
            toastError(error);
        }
    };
    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {planId
                        ? `${i18n.t("planModal.title.edit")}`
                        : `${i18n.t("planModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={plan}
                    enableReinitialize={true}
                    validationSchema={planSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSavePlan(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, touched, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.textContainer}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("planModal.form.name")}
                                        name="name"
                                        autoFocus
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.textContainer}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("planModal.form.users")}
                                        type="number"
                                        name="users"
                                        autoFocus
                                        error={touched.users && Boolean(errors.users)}
                                        helperText={touched.users && errors.users}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                        fullWidth
                                    />
                                    <Field
                                        as={TextField}
                                        label={i18n.t("planModal.form.connections")}
                                        type="number"
                                        name="connections"
                                        autoFocus
                                        error={touched.connections && Boolean(errors.connections)}
                                        helperText={touched.connections && errors.connections}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                        fullWidth
                                    />
                                    <Field
                                        as={TextField}
                                        label={i18n.t("planModal.form.queues")}
                                        type="number"
                                        name="queues"
                                        autoFocus
                                        error={touched.queues && Boolean(errors.queues)}
                                        helperText={touched.queues && errors.queues}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.textContainer}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("planModal.form.value")}
                                        type="number"
                                        name="value"
                                        autoFocus
                                        error={touched.value && Boolean(errors.value)}
                                        helperText={touched.value && errors.value}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                        fullWidth
                                    />
                                    <label style={{ display: "flex", alignItems: "center" }}>
                                        <Field
                                            as={Checkbox}
                                            label={i18n.t("planModal.form.isIntegrationMode")}
                                            type="checkbox"
                                            name="isIntegrationMode"
                                            autoFocus                                            
                                            variant="outlined"
                                            margin="dense"
                                            color="primary"                                            
                                        />
                                        {i18n.t("planModal.form.isIntegrationMode")}
                                    </label>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("planModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {planId
                                        ? `${i18n.t("planModal.buttons.okEdit")}`
                                        : `${i18n.t("planModal.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
}

export default PlanModal;