import { useLayoutEffect, useMemo, useRef } from "react";

export const useDebounce = (
    callback,
    delay,
  ) => {
    const callbackRef = useRef(callback);
    useLayoutEffect(() => {
      callbackRef.current = callback;
    });
    let timer;
    const naiveDebounce = (
      func,
      delayMs,
      args,
    ) => {
      clearTimeout(timer);
      timer = setTimeout(() => { 
        func(args);
      }, delayMs);
    };
    return useMemo(() => (...args) => naiveDebounce(
      callbackRef.current,
      delay, 
      ...args,
      // eslint-disable-next-line
    ), [delay]);
  };