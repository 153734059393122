import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField } from "@material-ui/core";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { i18n } from "../../translate/i18n";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import whatsBackground from "../../assets/wa-background.png";
import whatsDarkBackground from "../../assets/wa-dark-background.jpg";
import MarkdownWrapper from "../MarkdownWrapper";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";

const filter = createFilterOptions({
	trim: true,
});

const useStyles = makeStyles((theme) => ({
	messagesList: {
		backgroundImage: theme.palette.type === "dark"? `url(${whatsDarkBackground})` : `url(${whatsBackground})`,
		marginTop: 10,
		marginBottom: 20,		
		borderRadius: 5,
		padding: 20,
	},
	messageLeft: {
		marginRight: 20,
		marginTop: 2,
		minWidth: 100,
		maxWidth: 600,
		height: "auto",
		display: "block",
		position: "relative",
		whiteSpace: "pre-wrap",
		backgroundColor: "#ffffff",
		color: "#303030",
		borderTopLeftRadius: 0,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		paddingLeft: 5,
		paddingRight: 5,
		paddingTop: 5,
		paddingBottom: 0,
		boxShadow: "0 1px 1px #b3b3b3",
	},
	messageRight: {
		marginLeft: 20,
		marginTop: 2,
		minWidth: 100,
		maxWidth: 600,
		height: "auto",
		display: "block",
		position: "relative",
		whiteSpace: "pre-wrap",
		backgroundColor: "#dcf8c6",
		color: "#303030",
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 0,
		paddingLeft: 5,
		paddingRight: 5,
		paddingTop: 5,
		paddingBottom: 0,
		boxShadow: "0 1px 1px #b3b3b3",
	},
	timestamp: {
		fontSize: 11,
		position: "absolute",
		bottom: 0,
		right: 5,
		color: "#999",
	},
	textField: {
		marginRight: theme.spacing(1),
		width: "100%",
	},
}));

const ForwardMessageModal = ({ open, onClose, selectedMessage }) => {
	const classes = useStyles();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);	
    useEffect(() => {		
		if (!open || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};
			fetchContacts();
		}, 500);				
		return () => clearTimeout(delayDebounceFn);		
	}, [searchParam, open]);
    const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
	};
    const handleForwardMessage = async contact => {		
		const data = {			
			contactId: contact.id,
			messageId: selectedMessage.id
		};
		setLoading(true);
		try {
			await api.post("/messageforward", data);
			onClose();
		} catch (error) {
			toast.error(i18n.t("forwardMessageModal.forwardFailed"));
		} finally {
			setLoading(false);
		}
    };
    const handleSelectOption = (_, newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
		} 
	};
    const createAddContactOption = (filterOptions, params) => 
			filter(filterOptions, params);
	
    const renderOption = option => 
		`${option.name} - ${option.number}`;
    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">
					{i18n.t("forwardMessageModal.title")}
				</DialogTitle>
                <DialogContent dividers>
					<div className={classes.messagesList}>
						<div className={selectedMessage.fromMe? classes.messageRight: classes.messageLeft}>
							<MarkdownWrapper>{selectedMessage.body}</MarkdownWrapper>
							<span className={classes.timestamp}>							
								{selectedMessage.createdAt? format(parseISO(selectedMessage.createdAt), "HH:mm") : ''}
							</span>
						</div>
					</div>
					<Autocomplete
						options={options}
						loading={loading}
						style={{ minWidth: 500 }}
						clearOnBlur
						autoHighlight
						freeSolo
						clearOnEscape
						getOptionLabel={renderOption}
						renderOption={renderOption}
						filterOptions={createAddContactOption}
						onChange={(e, newValue) => handleSelectOption(e, newValue)}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("forwardMessageModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onChange={e => setSearchParam(e.target.value)}								
								onKeyPress={e => {
									if (loading || !selectedContact) return;
									else if (e.key === "Enter") {
										handleForwardMessage(selectedContact);
									}
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>					
                </DialogContent>
                <DialogActions>
                <Button
						onClick={handleClose}
						color="secondary"
						disabled={false}
						variant="outlined"
					>
						{i18n.t("forwardMessageModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={false}
						onClick={() => handleForwardMessage(selectedContact)}
						color="primary"
						loading={false}
					>
						{i18n.t("forwardMessageModal.buttons.send")}
					</ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ForwardMessageModal;