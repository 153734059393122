import { useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import api from '../../services/api';

const stripePromise = loadStripe("pk_test_51QSL12CO3SChHmAYvlKzdHmHpGxzn2kmk7ijGBT3SdYaGutULVS0DYhP4dnPAwKDZeIbGA5JEg91yWpMSYLqgOzu007RXwOP4j");

const CheckoutForm = () => {    
    const fetchClientSecret = useCallback(() => {
        return api.post("/checkout/create-session")
            .then(response => response.data.clientSecret);
    }, []);

    const options = { fetchClientSecret };

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>            
        </div>
    )
}

export default CheckoutForm;