import { Button, Divider, makeStyles, useTheme } from "@material-ui/core";
import LocationPreview from "../LocationPreview";
import ModalImageCors from "../ModalImageCors";
import VcardPreview from "../VcardPreview";
import { GetApp, OpenInBrowser } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import Audio from "../Audio";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
    messageMedia: {
        objectFit: "cover",
        width: 250,
        height: 200,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },
    downloadMedia: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "inherit",
        padding: 10,
    },
    spacerButton: {
        padding: 10
    }
}));

const MessageMedia = ({ message }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    if (!message.mediaUrl && message.mediaType !== "location" && message.mediaType !== "vcard" && message.mediaType !== "multi_vcard") return <></>;
    if (message.mediaType === "location" && message.body.split('|').length >= 2) {
        let locationParts = message.body.split('|')
        let imageLocation = locationParts[0]
        let linkLocation = locationParts[1]
        let descriptionLocation = null
        if (locationParts.length > 2)
            descriptionLocation = message.body.split('|')[2]
        return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    } else if (message.mediaType === "vcard") {
        let array = message.body.split("\n");
        let obj = [];
        let contact = "";
        for (let index = 0; index < array.length; index++) {
            const v = array[index];
            let values = v.split(":");
            for (let ind = 0; ind < values.length; ind++) {
                if (values[ind].indexOf("+") !== -1) {
                    obj.push({ number: values[ind] });
                }
                if (values[ind].indexOf("FN") !== -1) {
                    contact = values[ind + 1];
                }
            }
        }
        return <VcardPreview contact={contact} numbers={obj[0]?.number} />
    }
    /*else if (message.mediaType === "multi_vcard") {
      console.log("multi_vcard")
      console.log(message)
    	
      if(message.body !== null && message.body !== "") {
        let newBody = JSON.parse(message.body)
        return (
          <>
            {
            newBody.map(v => (
              <VcardPreview contact={v.name} numbers={v.number} />
            ))
            }
          </>
        )
      } else return (<></>)
    }*/
    else if (/^.*\.(jpeg|png|gif|bmp|webp|svg\+xml|tiff|heif|heic)?$/i.exec(message.mediaUrl) && message.mediaType.includes("image")) {
        return <ModalImageCors imageUrl={message.mediaUrl} />;
    } else if (message.mediaType.includes("audio")) {
        return <Audio url={message.mediaUrl} />
    } else if (message.mediaType.includes("video")) {
        return (
            <video
                className={classes.messageMedia}
                src={message.mediaUrl}
                controls
            />
        );
    } else {
        const handleDownload = () => {
            const fileName = message.mediaUrl.substring(message.mediaUrl.lastIndexOf("/") + 1);
            fetch(message.mediaUrl)
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                })
                .catch((err) => {
                    console.log(err);
                    toastError(err);
                });
        };
        return (
            <>
                <div className={classes.downloadMedia}>
                    <Button
                        startIcon={<OpenInBrowser />}
                        color="primary"
                        variant="outlined"
                        target="_blank"
                        href={message.mediaUrl}
                    >
                        {i18n.t("messagesList.actions.preview")}
                    </Button>
                    <div className={classes.spacerButton} />
                    <Button
                        startIcon={<GetApp />}
                        color="primary"
                        variant="outlined"
                        onClick={handleDownload}
                    >
                        {i18n.t("messagesList.actions.download")}
                    </Button>
                </div>
                <Divider />
            </>
        );
    }
};

export default MessageMedia;