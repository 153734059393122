import { List, makeStyles, Paper } from "@material-ui/core";
import { useEffect, useReducer, useState } from "react";
import useCompany from "../../hooks/useCompany";
import { useHistory } from "react-router-dom";
import { i18n } from "../../translate/i18n";
import connectToSocket from "../../services/socket-io";
import { getAppMessageEvent, getContactEvent, getTicketEvent } from "../../utils/constants";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import Ticket from "./Ticket";
import TicketsListSkeleton from "../../components/TicketsListSkeleton";

const useStyles = makeStyles((theme) => ({
    ticketsList: {
        flex: 1,
        height: "100%",
        maxHeight: "calc(100vh - 64px)",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        background: theme.palette.background.default
    },
    noTicketsContainer: {
        display: "flex",
        height: "100%",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    noTicketsTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },
    noTicketsText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },
    list: {
        paddingTop: 0
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_TICKETS") {
        const newTickets = action.payload;

        newTickets.forEach(ticket => {
            const ticketIndex = state.findIndex(t => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = formatTicket(ticket);
                if (ticket.unreadMessages > 0) {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                }
            } else {
                state.push(formatTicket(ticket));
            }
        });

        return [...state];
    }

    if (action.type === "RESET_UNREAD") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = formatTicket(ticket);
        } else {
            state.unshift(formatTicket(ticket));
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = formatTicket(ticket);
            state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            state.unshift(formatTicket(ticket));
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_CONTACT") {
        const contact = action.payload;
        const ticketIndex = state.findIndex(t => t.contactId === contact.id);
        if (ticketIndex !== -1) {
            state[ticketIndex].contact = contact;
        }
        return [...state];
    }

    if (action.type === "DELETE_TICKET") {
        const ticketId = action.payload;
        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
        }

        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

function formatTicket(ticket) {
    return {
        id: ticket.id,
        whatsapp: {
            name: ticket.whatsapp.name
        },
        contact: {
            name: ticket.contact.name,
            profilePicUrl: ticket.contact.profilePicUrl
        },
        queue: ticket.queue && {
            name: ticket.queue.name,
            color: ticket.queue.color
        },
        user: ticket.user && {
            name: ticket.user.name
        },
        lastMessage: ticket.lastMessage,
        updatedAt: ticket.updatedAt,
        unreadMessages: ticket.unreadMessages,
        status: ticket.status
    };
}

const TicketManager = () => {
    const classes = useStyles();
    const history = useHistory();
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(1);
    const [loading, setLoading] = useState(false);
    const [ticketsList, dispatch] = useReducer(reducer, []);
    const companyId = useCompany();

    useEffect(() => {
        const fetchAllTickets = async () => {
            setLoading(true);
            try {
                const { data } = await api.get(`/ticket-all?pageNumber=${pageNumber}`);
                setHasMore(data.hasMore);
                dispatch({ type: "LOAD_TICKETS", payload: data.tickets });
            } catch (error) {
                toastError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchAllTickets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    useEffect(() => {
        if (!companyId) return;
        const socket = connectToSocket();
        socket.on("connect", () => socket.emit("joinNotification"));
        socket.on(getTicketEvent(companyId), data => {
            if (data.action === "delete") {
                dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
                return;
            }
            if (data.action === "updateUnread") {
                dispatch({ type: "RESET_UNREAD", payload: data.ticketId });
                return;
            }
            if (data.action === "update") {
                dispatch({ type: "UPDATE_TICKET", payload: data.ticket });
            }
        });
        socket.on(getAppMessageEvent(companyId), data => {
            if (data.action === "create") {
                dispatch({ type: "UPDATE_TICKET_UNREAD_MESSAGES", payload: data.ticket });
            }
        });
        socket.on(getContactEvent(companyId), data => {
            if (data.action === "update") {
                dispatch({ type: "UPDATE_TICKET_CONTACT", payload: data.contact });
            }
        });
        return () => {
            if (!socket.connected) return;
            socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    const handleSelectTicketClick = (ticketId) => {
        history.push(`/tickets-manager/${ticketId}`);
    };

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };
    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            e.currentTarget.scrollTop = scrollTop - 100;
            loadMore();
        }
    };

    return (
        <>
            {
                ticketsList.length === 0 && !loading ?
                    (
                        <div className={classes.noTicketsContainer}>
                            <span className={classes.noTicketsTitle}>
                                {i18n.t("ticketsList.noTicketsTitle")}
                            </span>
                            <p className={classes.noTicketsText}>
                                {i18n.t("ticketsList.noTicketsMessage")}
                            </p>
                        </div>
                    ) :
                    (
                        <Paper
                            square
                            elevation={0}
                            className={classes.ticketsList}
                            onScroll={handleScroll}
                        >
                            <List
                                className={classes.list}
                            >
                                {ticketsList.map(ticket => (
                                    <Ticket
                                        key={ticket.id}
                                        ticket={ticket}
                                        onClick={handleSelectTicketClick}
                                    />
                                ))}
                                {loading && <TicketsListSkeleton />}
                            </List>
                        </Paper>
                    )
            }
        </>

    );
};

export default TicketManager;