import { parseISO, format, isSameDay } from "date-fns";

const TicketDate = ({ date }) => {
    const formatDate = isSameDay(parseISO(date), new Date())
        ? "HH:mm"
        : "dd/MM/yyyy";
    const formatedDate = format(parseISO(date), formatDate);
    return (
        formatedDate
    );
};

export default TicketDate;