import { IconButton, makeStyles, useTheme } from "@material-ui/core";
import { AccessTime, Block, Done, DoneAll, ExpandMore, Reply } from "@material-ui/icons";
import { Fragment } from "react"
import MessageMedia from "./MessageMedia";
import MarkdownWrapper from "../MarkdownWrapper";
import { i18n } from "../../translate/i18n";
import { format, isSameDay, parseISO } from "date-fns";
import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import FormatMessage from "../../helpers/FormatMessage";

const useStyles = makeStyles((theme) => ({
    dailyTimestamp: {
        alignItems: "center",
        textAlign: "center",
        alignSelf: "center",
        width: "110px",
        backgroundColor: "#e1f3fb",
        margin: "10px",
        borderRadius: "10px",
        boxShadow: "0 1px 1px #b3b3b3",
    },
    dailyTimestampText: {
        color: "#808888",
        padding: 8,
        alignSelf: "center",
        marginLeft: "0px",
    },
    textContentItem: {
        overflowWrap: "break-word",
        padding: "3px 80px 6px 6px",
    },
    textContentItemDeleted: {
        fontStyle: "italic",
        color: "rgba(0, 0, 0, 0.36)",
        overflowWrap: "break-word",
        padding: "0px 80px 6px 6px",
    },
    deletedIcon: {
        fontSize: 18,
        verticalAlign: "middle",
        marginRight: 4,
    },
    quotedContainerLeft: {
        margin: "-3px -80px 6px -6px",
        overflow: "hidden",
        backgroundColor: theme.palette.background.messageQuotedCardLeft,
        borderRadius: "7.5px",
        display: "flex",
        position: "relative",
    },
    quotedContainerRight: {
        margin: "-3px -80px 6px -6px",
        overflowY: "hidden",
        backgroundColor: theme.palette.background.messageQuotedCardRight,
        borderRadius: "7.5px",
        display: "flex",
        position: "relative",
    },
    quotedSideColorLeft: {
        flex: "none",
        width: "4px",
        backgroundColor: "#6bcbef",
    },
    quotedSideColorRight: {
        flex: "none",
        width: "4px",
        backgroundColor: "#35cd96",
    },
    quotedMsg: {
        padding: 10,
        maxWidth: 300,
        height: "auto",
        display: "block",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
    },
    messageContactName: {
        display: "flex",
        color: "#6bcbef",
        fontWeight: 500,
    },
    ackIcons: {
        fontSize: 18,
        verticalAlign: "middle",
        marginLeft: 4,
    },
    ackDoneAllIcon: {
        color: green[500],
        fontSize: 18,
        verticalAlign: "middle",
        marginLeft: 4,
    },
    messageRight: {
        marginLeft: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: "auto",
        display: "block",
        position: "relative",
        "&:hover #messageActionsButton": {
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
        },
        whiteSpace: "pre-wrap",
        backgroundColor: theme.palette.background.messageCard,
        color: theme.palette.text.color,
        alignSelf: "flex-end",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: `0 1px 1px ${theme.palette.background.messageCard}`,
    },
    messageLeft: {
        marginRight: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: "auto",
        display: "block",
        position: "relative",
        "&:hover #messageActionsButton": {
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
        },
        whiteSpace: "pre-wrap",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.color,
        alignSelf: "flex-start",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: `0 1px 1px ${theme.palette.background.default}`,
    },
    messageActionsButton: {
        display: "none",
        position: "relative",
        color: "#999",
        zIndex: 1,
        backgroundColor: "inherit",
        opacity: "90%",
        "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
    },
    timestamp: {
        fontSize: 11,
        position: "absolute",
        bottom: 0,
        right: 5,
        color: "#999",
    },
}));

const Message = ({ isGroup, isFirstMessage, isLastMessage, previousMessageDay, previousFromMe, lastMessageRef, message, onMessageOptionClick }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const renderDailyTimestamps = (message) => {
        if (isFirstMessage) {
            return (
                <span
                    className={classes.dailyTimestamp}
                    key={`timestamp-${message.id}`}
                >
                    <div className={classes.dailyTimestampText}>
                        {i18n.t("dateFormat", { date: parseISO(message.createdAt) })}
                    </div>
                </span>
            );
        }
        if (!isLastMessage) {
            let messageDay = parseISO(message.createdAt);
            if (!isSameDay(messageDay, parseISO(previousMessageDay))) {
                return (
                    <span
                        className={classes.dailyTimestamp}
                        key={`timestamp-${message.id}`}
                    >
                        <div className={classes.dailyTimestampText}>
                            {i18n.t("dateFormat", { date: parseISO(message.createdAt) })}
                        </div>
                    </span>
                );
            }
        }
        if (isLastMessage) {
            return (
                <div
                    key={`ref-${message.createdAt}`}
                    ref={lastMessageRef}
                    style={{ float: "left", clear: "both" }}
                />
            );
        }
    };
    const renderMessageDivider = (message) => {
        if (!isLastMessage && !isFirstMessage) {
            let messageUser = message.fromMe;
            if (messageUser !== previousFromMe) {
                return (
                    <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
                );
            }
        }
    };
    const renderForwarded = (message) => {
        if (!message.isForwarded) return;
        return (
            <div className={clsx(classes.textContentItem, {
                [classes.textContentItemDeleted]: message.isForwarded,
            })}>
                <Reply
                    color="disabled"
                    fontSize="small"
                    className={classes.deletedIcon}
                />
                <span style={{ color: "#808888" }}>
                    {i18n.t("messagesList.messageOptions.forwarded")}
                </span>
            </div >
        );
    }
    const renderQuotedMessage = (message) => {
        if (!message.quotedMsg) return;
        return (
            <div
                className={clsx(classes.quotedContainerLeft, {
                    [classes.quotedContainerRight]: message.fromMe,
                })}
            >
                <span
                    className={clsx(classes.quotedSideColorLeft, {
                        [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
                    })}
                ></span>
                <div className={classes.quotedMsg}>
                    {!message.quotedMsg?.fromMe && (
                        <span className={classes.messageContactName}>
                            {message.quotedMsg?.contact?.name}
                        </span>
                    )}
                    <MessageMedia message={message.quotedMsg} />
                    <MarkdownWrapper>{FormatMessage(message.quotedMsg?.body, message.quotedMsg?.mediaUrl)}</MarkdownWrapper>
                </div>
            </div>
        );
    };
    const renderMessageAck = (message) => {
        if (!message.fromMe) return;
        if (message.ack === 0) {
            return <AccessTime fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 1) {
            return <Done fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 2) {
            return <DoneAll fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 3 || message.ack === 4) {
            return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
        }
    };
    return (
        <Fragment key={message.id}>
            {renderDailyTimestamps(message)}
            {renderMessageDivider(message)}
            <div className={clsx(classes.messageRight, {
                [classes.messageLeft]: !message.fromMe
            })}>
                {onMessageOptionClick && (
                    <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        className={classes.messageActionsButton}
                        onClick={(e) => onMessageOptionClick(e, message)}
                    >
                        <ExpandMore />
                    </IconButton>
                )}
                {!message.fromMe && isGroup && (
                    <span className={classes.messageContactName}>
                        {message.contact?.name}
                    </span>
                )}
                {renderForwarded(message)}
                <MessageMedia message={message} />
                <div
                    className={clsx(classes.textContentItem, {
                        [classes.textContentItemDeleted]: message.isDeleted,
                    })}
                >
                    {message.isDeleted && (
                        <Block
                            color="disabled"
                            fontSize="small"
                            className={classes.deletedIcon}
                        />
                    )}
                    {renderQuotedMessage(message)}
                    <MarkdownWrapper>{FormatMessage(message.body, message.mediaUrl)}</MarkdownWrapper>
                    <span className={classes.timestamp}>
                        {format(parseISO(message.createdAt), "HH:mm")}
                        {renderMessageAck(message)}
                    </span>
                </div>
            </div>
        </Fragment >
    );
};

export default Message;