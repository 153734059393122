import { rules } from "../../rules";

const check = (role, action, data) => {
	const permissions = rules[role];
	if (!permissions) return false;

	// static permissions
	if (permissions.static?.includes(action)) return true;

	// dynamic permissions
	if (permissions.dynamic?.[action]) {
		return permissions.dynamic[action](data);
	}

	// check attributes (ABAC)
	if (permissions.attributes?.[action]) {
		return permissions.attributes[action](data);
	}

	return false;
};

const Can = ({ role, perform, data, yes, no }) =>
	check(role, perform, data) ? yes() : no();

Can.defaultProps = {
	yes: () => null,
	no: () => null,
};

{/* <Can
	role={user.role}
	perform="edit_post"
	data={{ ownerId: post.ownerId, currentUserId: user.id }}
	yes={() => <p>Você pode editar este post</p>}
	no={() => <p>Você não tem permissão para editar este post</p>}
/> */}


export { Can };
