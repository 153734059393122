import { Avatar, Button, Card, CardHeader, CircularProgress, makeStyles, Paper } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import whatsBackground from "../../assets/wa-background.png";
import whatsDarkBackground from "../../assets/wa-dark-background.jpg";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useReducer, useRef, useState } from "react";
import { green } from "@material-ui/core/colors";
import Message from "../../components/Message/Message";
import toastError from "../../errors/toastError";
import useCompany from "../../hooks/useCompany";
import api from "../../services/api";
import connectToSocket from "../../services/socket-io";
import { getAppMessageEvent } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: "0",
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    ticketHeader: {
        display: "flex",
        flex: "none",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
        },
    },
    ticketInfo: {
        maxWidth: "50%",
        flexBasis: "50%",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "80%",
            flexBasis: "80%",
        },
    },
    messagesListWrapper: {
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    messagesList: {
        backgroundImage: theme.palette.type === "dark" ? `url(${whatsDarkBackground})` : `url(${whatsBackground})`,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: 20,
        overflowY: "scroll",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "90px",
        },
        ...theme.scrollbarStyles,
    },
    circleLoading: {
        color: green[500],
        position: "absolute",
        opacity: "70%",
        top: 0,
        left: "50%",
        marginTop: 12,
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_MESSAGES") {
        const messages = action.payload;
        const newMessages = [];

        messages.forEach((message) => {
            const messageIndex = state.findIndex((m) => m.id === message.id);
            if (messageIndex !== -1) {
                state[messageIndex] = message;
            } else {
                newMessages.push(message);
            }
        });

        return [...newMessages, ...state];
    }

    if (action.type === "ADD_MESSAGE") {
        const newMessage = action.payload;
        const [firstItem] = state;
        if (firstItem) {
            if (firstItem.ticketId !== newMessage.ticketId) return state;
        }
        const messageIndex = state.findIndex((m) => m.id === newMessage.id);
        if (messageIndex !== -1) {
            state[messageIndex] = newMessage;
        } else {
            state.push(newMessage);
        }
        return [...state];
    }

    if (action.type === "UPDATE_MESSAGE") {
        const messageToUpdate = action.payload;
        const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

        if (messageIndex !== -1) {
            state[messageIndex] = messageToUpdate;
        }

        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const MessageManager = () => {
    const classes = useStyles();
    const history = useHistory()
    const lastMessageRef = useRef();
    const companyId = useCompany();
    const { ticketId } = useParams();
    const currentTicketId = useRef(ticketId);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [ticket, setTicket] = useState(undefined);
    const [messagesList, dispatch] = useReducer(reducer, []);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
        const fetchAll = async () => {
            try {
                setLoading(true);
                const [ticket, messagesData] = await Promise.all([fetchTicket(), fetchMessages(1)]);
                setTicket(ticket);
                dispatch({ type: "LOAD_MESSAGES", payload: messagesData.messages });
                setHasMore(messagesData.hasMore);
                scrollToBottom();
            } catch (error) {
                toastError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketId]);

    useEffect(() => {
        if (!ticketId || pageNumber === 1) return;
        const delayDebounceFn = setTimeout(() => {
            const fetchAll = async () => {
                setLoading(true);
                try {
                    const data = await fetchMessages(pageNumber);
                    if (currentTicketId.current === ticketId) {
                        dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
                        setHasMore(data.hasMore);
                    }
                    if (pageNumber === 1 && data.messages.length > 1) {
                        scrollToBottom();
                    }
                } catch (error) {
                    toastError(error);
                } finally {
                    setLoading(false);
                }
            };
            fetchAll();
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    useEffect(() => {
        if (!companyId) return;
        const socket = connectToSocket();
        socket.on("connect", () => socket.emit("joinChatBox", ticketId));
        socket.on(getAppMessageEvent(companyId), (data) => {
            if (data.action === "create") {
                dispatch({ type: "ADD_MESSAGE", payload: data.message });
                scrollToBottom();
            }
            if (data.action === "update") {
                dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
            }
        });
        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    const fetchTicket = async () => {
        if (!ticketId) return null;
        const { data } = await api.get(`/tickets/${ticketId}`);
        return data;
    };

    const fetchMessages = async (pageNumber) => {
        const { data } = await api.get(`/messages/${ticketId}?readMessages=false`, { params: { pageNumber } });
        return data;
    };

    const handleBack = () => {
        history.push("/tickets-manager");
    };

    const handleScroll = (e) => {
        if (!hasMore) return;
        const { scrollTop } = e.currentTarget;
        if (scrollTop === 0) {
            document.getElementById("messagesList").scrollTop = 1;
        }
        if (loading) return;
        if (scrollTop < 50) {
            loadMore();
        }
    };

    const scrollToBottom = () => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({});
        }
    };

    const loadMore = () => {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
    };

    return (
        <Paper
            variant="outlined"
            elevation={0}
            className={classes.mainWrapper}
        >
            <Card square className={classes.ticketHeader}>
                <Button color="primary" onClick={handleBack}>
                    <ArrowBackIos />
                </Button>
                <div className={classes.ticketInfo}>
                    <CardHeader
                        titleTypographyProps={{ noWrap: true }}
                        subheaderTypographyProps={{ noWrap: true }}
                        avatar={<Avatar src={ticket?.contact?.profilePicUrl} alt="contact_image" />}
                        title={`${ticket?.contact?.name} #${ticket?.id}`}
                        subheader={
                            ticket?.user &&
                            `${i18n.t("messagesList.header.assignedTo")} ${ticket?.user?.name}`
                        }
                    />
                </div>
            </Card>
            <div className={classes.messagesListWrapper}>
                <div
                    id="messagesList"
                    className={classes.messagesList}
                    onScroll={handleScroll}
                >
                    {messagesList.map((message, index) => (
                        <Message
                            key={index}
                            isGroup={ticket.isGroup}
                            isFirstMessage={index === 0}
                            isLastMessage={index === messagesList.length - 1}
                            previousMessageDay={index > 0 ? messagesList[index - 1].createdAt : undefined}
                            previousFromMe={index > 0 ? messagesList[index - 1].fromMe : false}
                            lastMessageRef={lastMessageRef}
                            message={message}
                        />
                    ))}
                </div>
                {loading && (
                    <div>
                        <CircularProgress className={classes.circleLoading} />
                    </div>
                )}
            </div>
        </Paper>
    );
};


export default MessageManager