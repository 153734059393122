import { Hidden, makeStyles, Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid"
import { i18n } from "../../translate/i18n";
import { useParams } from "react-router-dom";
import MessageManager from "./MessageManager";
import TicketManager from "./TicketManager";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "0.1rem",
        height: `calc(100% - 48px)`,
        overflowY: "hidden",
    },
    messagessWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "column"
    },
    ticketsListWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    welcomeMsg: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
        textAlign: "center",
        borderRadius: 0,
    },
}));

const TicketsManager = () => {
    const classes = useStyles();
    const { ticketId } = useParams();

    return (
        <Grid container direction="row" className={classes.root}>
            <Grid item xs={5}>
                <Paper className={classes.ticketsListWrapper}>
                    <TicketManager />
                </Paper>
            </Grid>
            <Grid item xs={7} className={classes.messagessWrapper}>
                {ticketId
                    ? <MessageManager />
                    : (
                        <Hidden only={["sm", "xs"]}>
                            <Paper className={classes.welcomeMsg}>
                                <span>{i18n.t("chat.noTicketMessage")}</span>
                            </Paper>
                        </Hidden>
                    )
                }
            </Grid>
        </Grid>
    );
};

export default TicketsManager;